// import logo from './logo.png';
import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import AgreementsAndroidEnPage from './AgreementsAndroidEnPage.js'
import AgreementsAndroidRuPage from './AgreementsAndroidRuPage.js'
import AgreementsIOSEnPage from './AgreementsAppleEnPage.js'
import AgreementsIOSRuPage from './AgreementsAppleRuPage.js'
import DeleteUserForm from './DeleteUserForm.js';

function App() {
  return (
    <div className="App">
      <main className="main-content">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/en/android-agreement" element={<AgreementsAndroidEnPage />} />
            <Route path="/ru/android-agreement" element={<AgreementsAndroidRuPage />} />
            <Route path="/en/ios-agreement" element={<AgreementsIOSEnPage />} />
            <Route path="/ru/ios-agreement" element={<AgreementsIOSRuPage />} />
            <Route path="/delete-user" element={<DeleteUserForm />} />
          </Routes>
        </Suspense>
      </main>
    </div>
  );
}

function HomePage() {
  return (
    <div className="App-header">
      <img src="/monaco.png" className="App-logo" />
      <p>
      <div className='App-description'>Apartments renovation with the most rated brand names and design trends in a fastest possible time and price.</div>
      </p>
      {/* <p><a href="#" className='App-link'>Contact us</a></p> */}
      <p>
        <a href="#" className='store-link'><img src="/appstore.png" /></a>
        <a href="#" className='store-link'><img src="/googleplay.png" /></a>
      </p>
  </div>
);
}

export default App;
